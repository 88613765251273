import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'meta_adset',
    title: 'Meta adset',
    channel: 'social',
    platform: 'meta',
    publishPlatformName: 'facebookmarketing',
    defaultTab: 'settings',
    description: 'Create an adset for a meta campaign',
    addBrickText: 'Add ad set',
    outputAction: 'publish',
    availableParentBricks: ['meta_campaign'],
    availableChildBricks: ['meta_ad'],
    additionalVars: ['meta_campaign', 'multiSocial_adset', 'multiSocial_campaign'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'component',
            component: 'settings'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ],
    config: {
        general: {
            publish: {
                previewType: 'settings'
            }
        }
    }
};

export default setup;
