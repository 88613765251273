import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_SETTINGS } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                identifier: 'meta_adset-title',
                label: 'Title',
                defaultValue: 'Meta ad set',
                maxLength: 150,
                useValueObject: false,
                fixedModel: true,
                dynamicValueActive: true
            },
            {
                type: 'select',
                model: 'status',
                label: 'Status',
                identifier: 'meta_adset-status',
                tooltip: 'Status',
                setFirstOption: true,
                options: {
                    PAUSED: 'PAUSED',
                    ACTIVE: 'ACTIVE'
                }
            },
            {
                type: 'dynamicData',
                title: 'Child title',
                identifier: 'meta_adset-child_title',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_campaign`,
                model: 'childTitle',
                itemType: 'input',
                label: 'Child title',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Pointer',
                identifier: 'meta_adset-pointer',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_adset`,
                model: 'pointer',
                itemType: 'input',
                label: 'Pointer',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Parent Pointer',
                identifier: 'meta_adset-parentPointer',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_adset`,
                model: 'parentPointer',
                itemType: 'input',
                label: 'Parent Pointer',
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Details',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'adAccountId',
                label: 'Ad account id',
                identifier: 'meta_adset-ad_account_id',
                metadataType: 'facebookAdAccount',
                config: {
                    fields: 'name'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'promoted_object.page_id',
                identifier: 'meta_adset-page_id',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.adAccountId`,
                label: 'Page',
                metadataType: 'facebookPage',
                config: {
                    accountId: '{{{data.settings.adAccountId}}}'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'promoted_object.pixel_id',
                identifier: 'meta_adset-pixel',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.adAccountId && additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_TRAFFIC'`,
                label: 'Data set',
                metadataType: 'facebookPixel',
                config: {
                    adAccountId: '{{{data.settings.adAccountId}}}',
                    fields: 'name,id'
                },
                useValueObject: false
            },
            {
                type: 'select',
                model: 'promoted_object.custom_event_type',
                label: 'Custom event type',
                condition: `blockData.promoted_object.pixel_id`,
                identifier: 'meta_adset-custom_event_type',
                tooltip:
                    'The event from an App Event of a mobile app, (Purchase, Lead or CompleteRegistration) event from Offline Conversion data, or tag of an conversion pixel.',
                options: {
                    '': '',
                    AD_IMPRESSION: 'Ad impression',
                    RATE: 'Rate',
                    TUTORIAL_COMPLETION: 'Tutorial completion',
                    CONTACT: 'Contact',
                    CUSTOMIZE_PRODUCT: 'Customize product',
                    DONATE: 'Donate',
                    FIND_LOCATION: 'Find location',
                    SCHEDULE: 'Schedule',
                    START_TRIAL: 'Start trial',
                    SUBMIT_APPLICATION: 'Submit application',
                    SUBSCRIBE: 'Subscribe',
                    ADD_TO_CART: 'Add to cart',
                    ADD_TO_WISHLIST: 'Add to wishlist',
                    INITIATED_CHECKOUT: 'Initiated checkout',
                    ADD_PAYMENT_INFO: 'Add payment info',
                    PURCHASE: 'Purchase',
                    LEAD: 'Lead',
                    COMPLETE_REGISTRATION: 'Complete registration',
                    CONTENT_VIEW: 'Content view',
                    SEARCH: 'Search',
                    SERVICE_BOOKING_REQUEST: 'Service booking request',
                    MESSAGING_CONVERSATION_STARTED_7D: 'Messaging conversation started 7 days',
                    LEVEL_ACHIEVED: 'Level achieve',
                    ACHIEVEMENT_UNLOCKED: 'Achievement unlocked',
                    SPENT_CREDITS: 'Spent credits',
                    CLICK_TO_JOIN_NEW_CHANNEL_MEMBER: 'Click to join new channel member',
                    LISTING_INTERACTION: 'Listing interaction',
                    D2_RETENTION: 'D2 retention',
                    D7_RETENTION: 'D7 retention',
                    OTHER: 'Other'
                }
            }
        ]
    },
    {
        title: 'Details',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'radioList',
                model: 'destination_type',
                label: 'Conversion location',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_TRAFFIC'`,
                identifier: 'meta_adset-destination_type',
                options: [
                    {
                        key: 'WEBSITE',
                        value: 'WEBSITE'
                    },
                    {
                        key: 'APP',
                        value: 'APP'
                    },
                    {
                        key: 'MESSENGER',
                        value: 'MESSENGER'
                    },
                    {
                        key: 'INSTAGRAM DIRECT',
                        value: 'INSTAGRAM_DIRECT'
                    }
                ]
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                setFirstOption: true,
                condition: `!additionalVars.meta_campaign.${MODEL_SETTINGS}.objective || additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === ''`,
                identifier: 'meta_adset-optimization_goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    NONE: 'None',
                    APP_INSTALLS: 'App installs',
                    AD_RECALL_LIFT: 'Ad recall lift',
                    ENGAGED_USERS: 'Engaged users',
                    EVENT_RESPONSES: 'Event responses',
                    IMPRESSIONS: 'Impressions',
                    LEAD_GENERATION: 'Lead generation',
                    QUALITY_LEAD: 'Quality lead',
                    LINK_CLICKS: 'Link clicks',
                    OFFSITE_CONVERSIONS: 'Offsite conversions',
                    PAGE_LIKES: 'Page likes',
                    POST_ENGAGEMENT: 'Post engagement',
                    QUALITY_CALL: 'Quality call',
                    REACH: 'Reach',
                    LANDING_PAGE_VIEWS: 'Landing page views',
                    VISIT_INSTAGRAM_PROFILE: 'Visit instagram profile',
                    VALUE: 'Value',
                    THRUPLAY: 'Thruplay',
                    DERIVED_EVENTS: 'Derived events',
                    APP_INSTALLS_AND_OFFSITE_CONVERSIONS: 'App installs and offsite conversions',
                    CONVERSATIONS: 'Conversions',
                    IN_APP_VALUE: 'In app value',
                    MESSAGING_PURCHASE_CONVERSION: 'Messaging purchase conversion',
                    MESSAGING_APPOINTMENT_CONVERSION: 'Messaging appointment conversion'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_AWARENESS'`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    IMPRESSIONS: 'Impressions',
                    AD_RECALL_LIFT: 'Ad recall lift',
                    THRUPLAY: 'Thruplay',
                    REACH: 'Reach'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                setFirstOption: true,
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_LEADS'`,
                identifier: 'meta_adset-optimization_goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    NONE: 'None',
                    APP_INSTALLS_AND_OFFSITE_CONVERSIONS: 'App installs and offsite conversions',
                    REACH: 'Reach',
                    LINK_CLICKS: 'Link clicks'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.destination_type === 'WEBSITE'`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    NONE: 'None',
                    LANDING_PAGE_VIEWS: 'Landing page views',
                    LINK_CLICKS: 'Link clicks',
                    IMPRESSIONS: 'Impressions',
                    REACH: 'Reach'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                setFirstOption: true,
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_TRAFFIC' && additionalVars.brick.data.destination_type === 'APP'`,
                identifier: 'meta_adset-optimization_goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    LINK_CLICKS: 'Link clicks',
                    REACH: 'Reach'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_TRAFFIC' && additionalVars.brick.${MODEL_SETTINGS}.destination_type === 'MESSENGER'`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    LINK_CLICKS: 'Link clicks',
                    IMPRESSIONS: 'Impressions',
                    REACH: 'Reach'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_TRAFFIC' && additionalVars.brick.${MODEL_SETTINGS}.destination_type === 'WEBSITE'`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    LINK_CLICKS: 'Link clicks',
                    LANDING_PAGE_VIEWS: 'Landing page views',
                    IMPRESSIONS: 'Impressions',
                    CONVERSATIONS: 'Conversions',
                    REACH: 'Reach'
                }
            },

            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_SALES' && additionalVars.brick.${MODEL_SETTINGS}.destination_type === 'WEBSITE'`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    LINK_CLICKS: 'Link clicks',
                    OFFSITE_CONVERSIONS: 'Offsite conversions',
                    REACH: 'Reach'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_SALES' && additionalVars.brick.${MODEL_SETTINGS}.destination_type === 'MESSENGER'`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    CONVERSATIONS: 'Conversations',
                    IMPRESSIONS: 'Impressions',
                    REACH: 'Reach',
                    LINK_CLICKS: 'Link clicks'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_SALES' && additionalVars.brick.${MODEL_SETTINGS}.destination_type === 'APP'`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    OFFSITE_CONVERSIONS: 'Offsite conversions',
                    REACH: 'Reach',
                    LINK_CLICKS: 'Link clicks'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_APP_PROMOTION' && (additionalVars.meta_campaign.${MODEL_SETTINGS}.smart_promotion_type!=='SMART_APP_PROMOTION' || !additionalVars.meta_campaign.${MODEL_SETTINGS}.smart_promotion_type)`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    APP_INSTALLS: 'App installs',
                    LINK_CLICKS: 'Link clicks',
                    APP_INSTALLS_AND_OFFSITE_CONVERSIONS: 'App installs and offsite conversions',
                    VALUE: 'Value'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.objective === 'OUTCOME_APP_PROMOTION' && additionalVars.meta_campaign.${MODEL_SETTINGS}.smart_promotion_type==='SMART_APP_PROMOTION'`,
                identifier: 'meta_adset-optimization_goal',
                setFirstOption: true,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    APP_INSTALLS: 'App installs',
                    LINK_CLICKS: 'Link clicks',
                    APP_INSTALLS_AND_OFFSITE_CONVERSIONS: 'App installs and offsite conversions',
                    VALUE: 'Value'
                }
            },
            {
                type: 'number',
                tooltip: 'Bid cap or target cost for this ad set.',
                model: 'bid_amount',
                identifier: 'meta_adset-bid_amount',
                label: 'Bid amount',
                startAdornment: '€'
            },

            {
                type: 'select',
                model: 'bid_strategy',
                condition: `(!additionalVars.meta_campaign.${MODEL_SETTINGS}.bid_strategy || additionalVars.meta_campaign.${MODEL_SETTINGS}.bid_strategy==='' || !additionalVars.meta_campaign.${MODEL_SETTINGS}.budget_optimization_on || additionalVars.meta_campaign.${MODEL_SETTINGS}.budget_optimization_on==='false') && (blockData.budget_optimization_on==='true' && additionalVars.brick.${MODEL_SETTINGS}.objective ==='OUTCOME_AWARENESS')`,
                label: 'Bid strategy',
                identifier: 'meta_adset-bid_strategy',
                tooltip: 'Bid strategy for this campaign',
                options: {
                    '': '',
                    LOWEST_COST_WITH_BID_CAP: 'Bid cap',
                    LOWEST_COST_WITHOUT_CAP: 'Highest volume'
                }
            },
            {
                type: 'select',
                model: 'bid_strategy',
                condition: `(!additionalVars.meta_campaign.${MODEL_SETTINGS}.bid_strategy || additionalVars.meta_campaign.${MODEL_SETTINGS}.bid_strategy==='' || !additionalVars.meta_campaign.${MODEL_SETTINGS}.budget_optimization_on || additionalVars.meta_campaign.${MODEL_SETTINGS}.budget_optimization_on==='false') && (blockData.budget_optimization_on==='true' && additionalVars.brick.${MODEL_SETTINGS}.objective && additionalVars.brick.${MODEL_SETTINGS}.objective!=='' && additionalVars.brick.${MODEL_SETTINGS}.objective!=='OUTCOME_AWARENESS')`,
                label: 'Bid strategy',
                identifier: 'meta_adset-bid_strategy',
                tooltip: 'Bid strategy for this campaign',
                options: {
                    '': '',
                    LOWEST_COST_WITH_BID_CAP: 'Bid cap',
                    LOWEST_COST_WITHOUT_CAP: 'Highest volume',
                    COST_CAP: 'Cost per result goal'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                label: 'Billing event',
                condition: `blockData.optimization_goal==='REACH'`,
                identifier: 'meta_adset-billing_event',
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    LINK_CLICKS: 'Link clicks'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                label: 'Billing event',
                condition: `blockData.optimization_goal!=='REACH'`,
                identifier: 'meta_adset-billing_event',
                setFirstOption: true,
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    IMPRESSIONS: 'Impressions'
                }
            },
            {
                type: 'select',
                model: 'frequency_control_specs.event',
                label: 'Frequency event',
                identifier: 'meta_adset-frequency_event',
                condition: `blockData.optimization_goal==="REACH"`,
                tooltip: 'Event name, only IMPRESSIONS currently.',
                options: {
                    IMPRESSIONS: 'Impressions',
                    VIDEO_VIEWS: 'Video views',
                    VIDEO_VIEWS_2S: 'Video views at least 2 seconds',
                    VIDEO_VIEWS_15S: 'Video views at least 15 seconds'
                }
            },
            {
                type: 'number',
                identifier: 'meta_adset-frequency_interval',
                tooltip: 'Interval period in days, between 1 and 90 (inclusive)',
                condition: `blockData.optimization_goal==="REACH"`,
                model: 'frequency_control_specs.interval_days',
                label: 'Interval days'
            },
            {
                type: 'number',
                identifier: 'meta_adset-max_frequency',
                tooltip: 'The maximum frequency, between 1 and 90 (inclusive)',
                condition: 'blockData.optimization_goal==="REACH"',
                model: 'frequency_control_specs.max_frequency',
                label: 'Max frequency'
            }
        ]
    },
    {
        title: 'Budget and schedule',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'date',
                model: 'start_time',
                identifier: 'meta_adset-start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'end_time',
                identifier: 'meta_adset-end_time',
                label: 'End date'
            },
            {
                type: 'buttonGroup',
                model: 'budget_mode',
                label: 'Budget mode',
                identifier: 'meta_adset-budget_mode',
                tooltip: 'Type of the budget',
                defaultValue: 'DAILY',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.budget_optimization_on === 'false'`,
                options: {
                    DAILY: 'Daily',
                    LIFETIME: 'Lifetime'
                }
            },
            {
                type: 'number',
                tooltip: 'budget',
                model: 'budget',
                label: 'Budget',
                identifier: 'meta_adset-budget',
                startAdornment: '€',
                condition: `additionalVars.meta_campaign.${MODEL_SETTINGS}.budget_optimization_on === 'false'`
            }
        ]
    },
    {
        title: 'Audience',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'targeting.geo_locations.locations',
                condition: `!additionalVars.datasetId`,
                label: 'Locations',
                identifier: 'meta_adset-locations',
                metadataType: 'facebookGeographic',
                config: {},
                useValueObject: false
            },
            {
                condition: `additionalVars.datasetId`,
                type: 'metadataInput',
                model: 'targeting.geo_locations.countries',
                label: 'Countries',
                identifier: 'meta_adset-locations',
                config: {
                    location_types: 'country'
                },
                metadataType: 'facebookGeographic',
                useValueObject: false
            },
            {
                condition: `additionalVars.datasetId`,
                type: 'metadataInput',
                model: 'targeting.geo_locations.cities',
                label: 'Cities',
                identifier: 'meta_adset-locations',
                config: {
                    location_types: 'city'
                },
                metadataType: 'facebookGeographic',
                useValueObject: false
            },
            {
                condition: `additionalVars.datasetId`,
                type: 'metadataInput',
                model: 'targeting.geo_locations.zips',
                label: 'Zip code',
                identifier: 'meta_adset-locations',
                config: {
                    location_types: 'zip'
                },
                metadataType: 'facebookGeographic',
                useValueObject: false
            },
            {
                condition: `additionalVars.datasetId`,
                type: 'metadataInput',
                model: 'targeting.geo_locations.regions',
                label: 'Regions',
                identifier: 'meta_adset-locations',
                config: {
                    location_types: 'region'
                },
                metadataType: 'facebookGeographic',
                useValueObject: false
            },
            {
                type: 'radioList',
                model: 'targeting.genders',
                label: 'Genders',
                identifier: 'meta_adset-genders',
                options: [
                    {
                        key: 'all',
                        value: 'All'
                    },
                    {
                        key: 'male',
                        value: 'Male'
                    },
                    {
                        key: 'female',
                        value: 'Female'
                    }
                ]
            },
            {
                type: 'facebookAge',
                model: 'targeting.age',
                identifier: 'meta_adset-age'
            },
            {
                type: 'metadataInput',
                model: 'targeting.interests',
                identifier: 'meta_adset-interests',
                label: 'Interests',
                tooltip: 'Reach specific audiences by looking at their interests, activities, the Pages they have liked and closely related topics.',
                metadataType: 'facebookDetailedTargeting',
                config: {
                    type: 'adinterest'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'targeting.demographics',
                identifier: 'meta_adset-demographics',
                label: 'Demographics',
                tooltip: 'Reach people based on education, employment, household and lifestyle details. Some data is available for the US only.',
                metadataType: 'facebookDetailedTargeting',
                config: {
                    type: 'adTargetingCategory',
                    searchClass: 'demographics'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'targeting.behaviors',
                identifier: 'meta_adset-behaviors',
                label: 'Behaviors',
                tooltip: 'Reach people based on purchase behaviors or intents, device usage and more. Some behavior data is available for US audiences only.',
                metadataType: 'facebookDetailedTargeting',
                config: {
                    type: 'adTargetingCategory',
                    searchClass: 'behaviors'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'targeting.locales',
                identifier: 'meta_adset-locales',
                label: 'Locales',
                tooltip: 'Languages',
                metadataType: 'facebookDetailedTargeting',
                config: {
                    type: 'adlocale',
                    limit: 1000
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Beneficiary and payer',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'dsa_beneficiary',

                identifier: 'meta_adset-dsa_beneficiary',
                label: 'Beneficiary',
                tooltip: 'The beneficiary is the person or organization benefiting from this ad set',
                useValueObject: false,
                dynamicValueActive: true
            },
            {
                type: 'text',
                model: 'dsa_payor',
                identifier: 'meta_adset-dsa_payor',
                label: 'Payer',
                tooltip: 'The payer is the person or organization paying for this ad set',
                useValueObject: false
            }
        ]
    }
    // TODO: To be discussed
    // {
    //     title: 'Optimization & delivery',
    //     itemType: 'block',
    //     type: 'multiInput',
    //     blockModel: MODEL_SETTINGS,
    //     opened: true,
    //     items: [
    //         {
    //             type: 'select',
    //             model: 'optimization_sub_event',
    //             label: 'Optimization sub event',
    //             tooltip: 'Optimization sub event for a specific optimization goal (ex: Sound-On event for Video-View-2s optimization goal)',
    //             options: {
    //                 NONE: 'None',
    //                 VIDEO_SOUND_ON: 'Video sound on',
    //                 TRIP_CONSIDERATION: 'Trip consideration',
    //                 TRAVEL_INTENT: 'Travel intent',
    //                 TRAVEL_INTENT_NO_DESTINATION_INTENT: 'Travel intent no destination intent',
    //                 TRAVEL_INTENT_BUCKET_01: 'Travel intent bucket one',
    //                 TRAVEL_INTENT_BUCKET_02: 'Travel intent bucket two',
    //                 TRAVEL_INTENT_BUCKET_03: 'Travel intent bucket three',
    //                 TRAVEL_INTENT_BUCKET_04: 'Travel intnte bucket four',
    //                 TRAVEL_INTENT_BUCKET_05: 'Travel intent bucket five'
    //             }
    //         },
    //         {
    //             type: 'select',
    //             model: 'multi_optimization_goal_weight',
    //             label: 'Multi optimization goal weight',
    //             tooltip: 'Multi optimization goal weight',
    //             options: {
    //                 UNDEFINED: 'Undefined',
    //                 BALANCED: 'Balanced',
    //                 PREFER_INSTALL: 'Prefer install',
    //                 PREFER_EVENT: 'Prefer event'
    //             }
    //         },

    //         {
    //             type: 'number',
    //             tooltip: 'Lifetime impressions. Available only for campaigns with buying_type=FIXED_CPM',
    //             model: 'lifetime_imps',
    //             label: 'Lifetime impressions'
    //         }
    //     ]
    // },
    //TODO: To be implemented
    // {
    //     title: 'Promoted object',
    //     itemType: 'block',
    //     type: 'multiInput',
    //     blockModel: MODEL_SETTINGS,
    //     opened: true,
    //     items: [
    //         {
    //             type: 'number',
    //             tooltip:
    //                 'The ID of a Facebook Application. Usually related to mobile or canvas games being promoted on Facebook for installs or engagement',
    //             model: 'promoted_object.application_id',
    //             label: 'Application id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of a Facebook conversion pixel. Used with offsite conversion campaigns.',
    //             model: 'promoted_object.pixel_id',
    //             label: 'Pixel id'
    //         },
    //         {
    //             type: 'select',
    //             model: 'promoted_object.custom_event_type',
    //             label: 'Custom event type',
    //             tooltip: 'The event from an App Event of a mobile app, not in the standard event list.',
    //             options: {
    //                 RATE: 'Rate',
    //                 TUTORIAL_COMPLETION: 'Tutorial completion',
    //                 CONTACT: 'Contact',
    //                 CUSTOMIZE_PRODUCT: 'Customize product',
    //                 DONATE: 'Donate',
    //                 FIND_LOCATION: 'Find location',
    //                 SCHEDULE: 'Schedule',
    //                 START_TRIAL: 'Start trial',
    //                 SUBMIT_APPLICATION: 'Submit application',
    //                 SUBSCRIBE: 'Subscribe',
    //                 ADD_TO_CART: 'Add to cart',
    //                 ADD_TO_WISHLIST: 'Add to whishlist',
    //                 INITIATED_CHECKOUT: 'Initiated checkout',
    //                 ADD_PAYMENT_INFO: 'Add payment info',
    //                 PURCHASE: 'Purchase',
    //                 LEAD: 'Lead',
    //                 COMPLETE_REGISTRATION: 'Complete registration',
    //                 CONTENT_VIEW: 'Content view',
    //                 SEARCH: 'Search',
    //                 SERVICE_BOOKING_REQUEST: 'Service booking request',
    //                 MESSAGING_CONVERSATION_STARTED_7D: 'Messaging conversation started 7 days',
    //                 LEVEL_ACHIEVED: 'Level achieved',
    //                 ACHIEVEMENT_UNLOCKED: 'Achievement unlocked',
    //                 SPENT_CREDITS: 'Spent credits',
    //                 LISTING_INTERACTION: 'Listing interaction',
    //                 D2_RETENTION: 'D2 Retention',
    //                 D7_RETENTION: 'D7 Retention',
    //                 OTHER: 'Other'
    //             }
    //         },
    //         {
    //             type: 'text',
    //             model: 'promoted_object.object_store_url',
    //             label: 'Object store url',
    //             tooltip:
    //                 "The uri of the mobile / digital store where an application can be bought / downloaded. This is platform specific. When combined with the 'application_id' this uniquely specifies an object which can be the subject of a Facebook advertising campaign.",
    //             placeholder: "e.g. 'https://monimanolovstore.com/product1'",
    //             useValueObject: false
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of an Offer from a Facebook Page.',
    //             model: 'promoted_object.offer_id',
    //             label: 'Offer id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of a Facebook Page',
    //             model: 'promoted_object.page_id',
    //             label: 'Facebook page id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of a Product Catalog. Used with Dynamic Product Ads.',
    //             model: 'promoted_object.product_catalog_id',
    //             label: 'Product catalog id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of the product item.',
    //             model: 'promoted_object.product_item_id',
    //             label: 'Product item id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of the instagram profile id.',
    //             model: 'promoted_object.instagram_profile_id',
    //             label: 'Instagram profile id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of a Product Set within an Ad Set level Product Catalog. Used with Dynamic Product Ads.',
    //             model: 'promoted_object.product_set_id',
    //             label: 'Product set id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of a Facebook Event',
    //             model: 'promoted_object.event_id',
    //             label: 'Event id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of the offline dataset.',
    //             model: 'promoted_object.offline_conversion_data_set_id',
    //             label: 'Offline conversion data set id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of the offline dataset.',
    //             model: 'promoted_object.fundraiser_campaign_id',
    //             label: 'Fundraiser campaign id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The event from an App Event of a mobile app, not in the standard event list.',
    //             model: 'promoted_object.custom_event_str',
    //             label: 'Fundraiser campaign id'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'The ID of a MCME conversion.',
    //             model: 'promoted_object.mcme_conversion_id',
    //             label: 'MCME Conversion id'
    //         }
    //     ]
    // },
];

export default settings;
